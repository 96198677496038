import {
  benefitIcon1,
  benefitIcon2,
  benefitIcon3,
  benefitIcon4,
  benefitIcon5,
  benefitImage2,
  chromecast,
  disc02,
  file02,
  homeSmile,
  notification2,
  notification3,
  notification4,
  plusSquare,
  recording01,
  recording03,
  roadmap2,
  roadmap3,
  searchMd,
  slack,
  sliders04,
} from "../assets";
import jira from "../assets/images/integrations/jira.svg";
import webhook from "../assets/images/integrations/webhook.svg";
import pagerduty from "../assets/images/integrations/pagerduty.svg";
import elasticsearch from "../assets/images/integrations/elasticsearch.svg";
import okta from "../assets/images/integrations/okta.svg";
import integrations from "../assets/images/integrations/integrations.svg";
import ibm from "../assets/images/integrations/ibm-qradar.svg";
import dd from '../assets/services/y.png'
export const navigation = [
  {
    id: "0",
    title: "Features",
    url: "/#features",
  },
  {
    id: "1",
    title: "Integrations",
    url: "/#integrations",
  },
  {
    id: "2",
    title: "Why ArkSentry",
    url: "/#how-to-use",
  },
  {
    id: "3",
    title: "How it works",
    url: "/#roadmap",
  },
  {
    id: "4",
    title: "Contact us",
    url: "/contact",
    onlyMobile: true,
  },
  {
    id: "5",
    title: "Sign in",
    url: "/login",
    onlyMobile: true,
  },
];

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

/* export const notificationImages = [notification4, notification3, notification2];
 */


export const arksentryServices = [
  "Comprehensive Protection",
  "24/7 Continuous Monitoring",
  "Real-Time Alerts",
  "AI & Human Intelligence",
  "Affordable Plans",
  "Seamless Integrations",
];

export const arksentryServicesIcons = [
  recording03,
  recording01,
  disc02,
  chromecast,
  sliders04,
];

export const roadmap = [
  {
    id: "0",
    title: "Data Sources Monitoring",
    text: "We actively monitor underground forums, black markets, Telegram channels, private groups, and the dark web to stay ahead in collecting valuable data. Our comprehensive approach allows us to gather comprehensive information efficiently and effectively.",
    imageUrl: dd,
    colorful: true,
  },
  {
    id: "1",
    title: "Data Collection & Parsing",
    text: "Our comprehensive data collection infrastructure utilizes integrated pipelines to systematically gather information from diverse sources. This data is then processed and analyzed through our advanced, AI-powered parsing tools, designed to intelligently interpret and organize the information, ensuring high accuracy and efficiency in data analysis.",
    imageUrl: roadmap2,
  },
  {
    id: "2",
    title: "Real Time Alerts",
    text: "Upon detecting any data related to our clients, we initiate immediate alerts to prompt swift action, addressing any potential breaches or security concerns.",
    imageUrl: roadmap3,
  },

];

export const collabText =
  "Integrate Arksentry with SIEM Solutions, Ticketing System, Messaging apps, and more.";

export const collabContent = [
  {
    id: "0",
    title: "Compromised Employees",
  
  },
  {
    id: "1",
    title: "Compromised Customers & Users",
  },
  {
    id: "2",
    title: "Compromised Vendors",
  },
  {
    id: "3",
    title: "Compromised Android Users",
  },
  {
    id: "4",
    title: "Compromised Leaked Databases",
  },
  {
    id: "5",
    title: "Compromised CXO’s",
  },
];

export const collabApps = [
  {
    id: "0",
    title: "Jira",
    icon: jira,
    width: 26,
    height: 36,
  },
  {
    id: "1",
    title: "webhook",
    icon: webhook,
    width: 34,
    height: 36,
  },
  {
    id: "2",
    title: "pagerduty",
    icon: pagerduty,
    width: 36,
    height: 28,
  },
  {
    id: "3",
    title: "Slack",
    icon: slack,
    width: 34,
    height: 35,
  },
  {
    id: "4",
    title: "ibm",
    icon: ibm,
    width: 34,
    height: 34,
  },
  {
    id: "5",
    title: "okta",
    icon: okta,
    width: 34,
    height: 34,
  },
  {
    id: "6",
    title: "integrations",
    icon: integrations,
    width: 26,
    height: 34,
  },
  {
    id: "7",
    title: "elasticsearch",
    icon: elasticsearch,
    width: 38,
    height: 32,
  },
];

export const pricing = [
  {
    id: "0",
    title: "Basic",
    description: "AI chatbot, personalized recommendations",
    price: "0",
    features: [
      "An AI chatbot that can understand your queries",
      "Personalized recommendations based on your preferences",
      "Ability to explore the app and its features without any cost",
    ],
  },
  {
    id: "1",
    title: "Premium",
    description: "Advanced AI chatbot, priority support, analytics dashboard",
    price: "9.99",
    features: [
      "An advanced AI chatbot that can understand complex queries",
      "An analytics dashboard to track your conversations",
      "Priority support to solve issues quickly",
    ],
  },
  {
    id: "2",
    title: "Enterprise",
    description: "Custom AI chatbot, advanced analytics, dedicated account",
    price: null,
    features: [
      "An AI chatbot that can understand your queries",
      "Personalized recommendations based on your preferences",
      "Ability to explore the app and its features without any cost",
    ],
  },
];

export const benefits = [
  {
    id: "0",
    title: "Darkweb Monitoring",
    text: "Identify stolen credentials, infected devices, and third-party data leaks swiftly within the recesses of the dark web, fortifying the security of your digital assets.",
    backgroundUrl: "./src/assets/benefits/card-1.svg",
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "1",
    title: "Breaches Intelligence",
    text: "Third parties offer opportunities but also pose cyber threats. Attackers exploit vulnerabilities in their systems to access your data. Extending protection beyond your organization is essential. We safeguard your data with third parties and promptly alert you to any breaches.",
    backgroundUrl: "./src/assets/benefits/card-2.svg",
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: false,
  },
  {
    id: "2",
    title: "Account Takeover Prevention",
    text: "By utilizing Employee Account Takeover (ATO) Prevention service, you can mitigate risk by receiving instant notifications when your employees’ credentials are found on the criminal underground, allowing you to respond promptly.",
    backgroundUrl: "./src/assets/benefits/card-3.svg",
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
    light: true,
  },

  {
    id: "3",
    title: "Attack Surface Managment",
    text: "Count on our continuous surveillance to shield your assets day and night, Gain comprehensive insights into your environment, empowering informed decisions and proactive measures to safeguard your assets effectively.",
    backgroundUrl: "./src/assets/benefits/card-4.svg",
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: false,
  },
 
  {
    id: "4",
    title: "Red Teaming",
    text: "We deliver red teaming and pentesting insights, exposing vulnerabilities in real time to fortify your defenses and outsmart threats.",
    backgroundUrl: "./src/assets/benefits/card-4.svg",
    iconUrl: benefitIcon5,
    imageUrl: benefitImage2,
    light: false,
  },

];
